<template>
  <v-container fill-height>
    <v-row 
      align="center" 
      justify="center"
    >
      <v-col 
        xs="12" 
        sm="8" 
        md="8" 
        lg="4"
        xl="3"
      >
        <SigninCard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SigninCard } from '@components/Auth'

export default {
  components: {
    SigninCard
  }
}
</script>